import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Import services
import { AuthService } from '../auth.service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { 

  }
  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      this.authService.user$.subscribe(user => {
        if (user && !user.auth.isAnonymous) {
          resolve(true);
        } else {
          console.log('User is not logged in or is anonymous'); 
          this.router.navigate(['/home']); 
          resolve(false);
        }
      });
    });
  }
}